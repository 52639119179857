import { getCookie, setCookie, removeCookie } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import { COOKIE_KEY, PERSONALIZED_SWITCH } from './constants'

// 1-程序化广告 2-个性化推荐 3-个性化消息通知
export const SWITCH_MAP = {
  s: 'search',
  r: 'recommendation',
  u: 'ug',
}
  
export const SWITCH_TYPE_MAP = {
  ug: 'u',
  recommendation: 'r',
  search: 's'
}
  
export const getReversedMap = obj => {
  return Object.keys(obj).reduce((acc, key) => {
    acc[obj[key]] = key
    return acc
  }, {})
}
export  const COOKIE_MAP = {
  s: 's',
  r: 'r',
  u: 'u',
}

export  const SWITCH_REPORT_TYPE_MAP = {
  ug: 'user_growth',
  recommendation: 'rec',
  search: 'search'
}

export const COOKIE_MAP_REVERSE = getReversedMap(COOKIE_MAP)
export const SWITCH_MAP_REVERSE = getReversedMap(SWITCH_MAP)

export const setCookiesObj = (obj) => {
  // s-1.r-0.u-1
  const cookieStr = Object.keys(obj).reduce((acc, key) => {
    acc = acc + COOKIE_MAP[key] + '-' + obj[key] + '.'
    return acc
  }, '')
  // eslint-disable-next-line @shein-aidc/common/notDirectUseCookie
  cookieStr.includes('0') ? setCookie({
    key: COOKIE_KEY,
    value: cookieStr.slice(0, -1),
    end: 3600 * 24 * 14,
    domain: getCurMainDomain()
  }) : removeCookie({ key: COOKIE_KEY, domain: getCurMainDomain() })
}

export const getCookiesObj = (key) => {
  // eslint-disable-next-line @shein-aidc/common/notDirectUseCookie
  const cookieStr = getCookie(key)
  return cookieStr && String(cookieStr).split('.').reduce((acc, str) => {
    const pairs = str.split('-')
    acc[COOKIE_MAP_REVERSE[pairs[0]]] = ~~pairs[1]
    return acc
  }, {})
}

export const storeSwitchToLocal = (status) => {
  localStorage.setItem(PERSONALIZED_SWITCH, JSON.stringify(status))
}


export const restoreSwitchFromLocal = () => {
  try {
    const switchObj = JSON.parse(localStorage.getItem(PERSONALIZED_SWITCH))
    return switchObj
  } catch(e) {
    // eslint-disable-next-line no-console
    console.log(e)
  }
}

export const hasValue = (obj) => {
  return obj && Object.keys(obj).length
}


function getCurMainDomain() {
  // shein.clbug.com => shein.com
  // us.shein.co => shein.co
  // m.shein.com => shein.com
  const domain = window.location.hostname
  const domainArr = domain.split('.')
  domainArr.shift()
  return domainArr.join('.')
}

export const loginHandlePersonalizedSwitch = () => {
  removeCookie({ key: COOKIE_KEY, domain: getCurMainDomain() })
  schttp({
    url: '/user/setting/personalized_switch',
    useBffApi: true
  }).then(data => {
    // console.log(data)
    if (data.code == 0 && data?.info) {
      const cookieStr = data.info.personalizedSwitch || ''
      // set switch in cookie
      // eslint-disable-next-line @shein-aidc/common/notDirectUseCookie
      cookieStr.includes('0') ? setCookie({
        key: COOKIE_KEY,
        value: cookieStr,
        end: 3600 * 24 * 14,
        domain: getCurMainDomain()
      }) : removeCookie({ key: COOKIE_KEY, domain: getCurMainDomain() })
    }
  })
}

export const getPersonalizedSwitch = () => {
  return schttp({
    url: '/user/setting/personalized_switch',
    useBffApi: true
  }).then(data => {
    // console.log(data)
    if (data.code == 0 && data?.info) {
      const cookieStr = data.info.personalizedSwitch || ''
      // set switch in cookie
      // eslint-disable-next-line @shein-aidc/common/notDirectUseCookie
      cookieStr.includes('0') ? setCookie({
        key: COOKIE_KEY,
        value: cookieStr,
        end: 3600 * 24 * 14,
        domain: getCurMainDomain()
      }) : removeCookie({ key: COOKIE_KEY, domain: getCurMainDomain() })
    }
  })
}

export const logoutHandlePersonalizedSwitch = () => {
  removeCookie({ key: COOKIE_KEY, domain: getCurMainDomain() })
}
